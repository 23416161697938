<template>
  <transition
    enter-active-class="duration-500"
    :enter-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-x-96 scale-150'"
    enter-to-class="opacity-100 transform translate-x-0  scale-100"
    leave-active-class="duration-500"
    leave-class="opacity-100 transform translate-x-0 scale-100"
    :leave-to-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-x-96 scale-150'"
  >
    <div class="fixed top-20 medium:top-16 right-0 origin-top-left backdrop-blur-lg bg-black not-support-backdrop-filter:backdrop-blur-fallback bg-opacity-50 h-[calc(100vh_-_80px)] medium:h-[calc(100vh_-_64px)] overflow-y-auto flex flex-col" v-if="showMenu">
      <div class="min-h-min">
        <h2 class="tracking-headline font-headline-bold text-5xl medium:text-4xl medium:mt-2 mt-4 medium:mb-1 mb-2 pl-10 ">TAKE ME <span class="text-ex-soft-purple">THERE</span></h2>
        <div>
          <a href="https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva?mcicid=cp.vegas" v-clickfeedback="'main-menu-button'" @click="analyticsEvent('menu_start_planning_clicked', 'outbound_links', 'https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva')" target="_blank" title="Start planning your trip to Las Vegas" class="flex items-center justify-between w-full h-16 medium:h-14 short:h-12 px-10 main-menu-button" ref="topOfMenu">
            <p class="font-body-medium medium:text-base text-lg mr-auto leading-[1rem]">START PLANNING</p>
            <img :src="menuStartPlanning" class="h-6" alt="">
          </a>
        </div>
        
        <h2 class="tracking-headline font-headline-bold text-5xl medium:text-4xl medium:mt-2 mt-4 medium:mb-1 mb-2 pl-10">DISCOVER YOUR <span class="text-ex-soft-purple">OTHER PERSONA</span></h2>
        <div>
          <router-link  v-for="(p,k,i) in personalities" :key="i" :to="p.link" v-clickfeedback="'main-menu-button'" @click="analyticsEvent('menu_persona_link_clicked', 'internal_links', p.title)" class="flex items-center justify-between w-full h-16 medium:h-14 short:h-12 px-10 main-menu-button" @click.native="closeMenu">
            <img :src="p.icon" class="h-14 medium:h-12 short:h-10" alt="">
            <p class="font-body-medium medium:text-base text-lg ml-2 mr-auto">{{p.title}}</p>
            <img :src="menuChevronRight" class="h-4" alt="">
          </router-link>
        </div>
        <h2 class="tracking-headline font-headline-bold text-5xl medium:text-4xl medium:mt-2 mt-4 medium:mb-1 mb-2 pl-10">REPLAY <span class="text-ex-soft-purple">EXPERIENCE</span></h2>
        <div>
          <router-link to="/" v-clickfeedback="'main-menu-button'" @click="analyticsEvent('menu_replay_clicked', 'internal_links', 'TAKE ME BACK')" class="flex items-center justify-between w-full h-16 medium:h-14 short:h-12 px-10 main-menu-button" @click.native="closeMenu">
            <p class="font-body-medium medium:text-base text-lg mr-auto leading-[1rem]">TAKE ME BACK</p>
            <img :src="menuReplay" class="h-6" alt="">
          </router-link> 
        </div>
        <div class="h-24"></div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import menuReplay from '@/assets/images/menu-replay.svg';
  import menuChevronRight from '@/assets/images/menu-chevron-right.svg';
  import menuStartPlanning from '@/assets/images/menu-start-planning.svg';
  import menuChampion from '@/assets/images/menu-champion-icon.png';
  import menuRomantic from '@/assets/images/menu-romantic-icon.png';
  import menuThrillseeker from '@/assets/images/menu-thrillseeker-icon.png';
  import menuFreespirit from '@/assets/images/menu-freespirit-icon.png';
  import menuSuperstar from '@/assets/images/menu-superstar-icon.png';

  
  export default {
    name: "MainMenu",
    data(){
      return {
        menuReplay,
        menuChevronRight,
        menuStartPlanning,
        personalities: {
                thrillseeker: {
                    title: 'THRILLSEEKER',
                    icon: menuThrillseeker,
                    link: '/thrillseeker'
                },
                superstar: {
                    title: 'SUPERSTAR',
                    icon: menuSuperstar,
                    link: '/superstar'
                },
                romantic: {
                    title: 'ROMANTIC',
                    icon: menuRomantic,
                    link: '/romantic'
                },
                champion: {
                    title: 'CHAMPION',
                    icon: menuChampion,
                    link: '/champion'
                },
                freespirit: {
                    title: 'FREE SPIRIT',
                    icon: menuFreespirit,
                    link: '/freespirit'
                },
            }
      }
    },
    computed:{
      ...mapGetters(['showMenu','motionDisabled']),
    },
    methods:{
      ...mapMutations(['setShowMenu']),
      closeMenu(){
        this.setShowMenu(false);
      }
    }
  }

</script>