<template>
  <div class="absolute top-0 left-0 h-full w-full pointer-events-none flex flex-col justify-between">

      <div class="h-16 short:h-12 bg-black bg-opacity-75 flex items-center xl:px-32 md:px-12 px-4 pointer-events-auto">
        <a href="https://expedia.com?mcicid=cp.vegas" title="Go to Expedia website" aria-label="Go to Expedia website" target="_blank">
          <img class="sm:h-8 h-6" :src="expediaLogo" alt="Expedia Logo" />
        </a>
        <template v-if="preview">
          <p v-clickfeedback="'btn-invisible'" class="btn-invisible sm:text-lg text-xs ml-auto">PREVIEW PLAYER</p>
        </template>
        <template v-else>
          <a href="https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva?mcicid=cp.vegas" v-clickfeedback="'btn-invisible'" @click="analyticsEvent('videoplayer_start_planning_clicked', 'outbound_links', 'https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva')" title="Start planning your trip to Las Vegas" target="_blank" class="btn-invisible sm:text-lg text-xs ml-auto">START PLANNING</a>
          <button class="btn-purple-hollow sm:text-lg text-xs sm:ml-4 ml-2 short:py-1" v-clickfeedback="'btn-purple-hollow'" @click="handleSkipButton">SKIP VIDEO</button> 
        </template>
      </div>

      <div v-show="!videoStatus.playing && !showLoadingSpinner" class="flex flex-grow justify-center items-center bg-black bg-opacity-50 pointer-events-auto">
        <button @click="handlePlayButton"><img :src="icons.playerPlayLarge" alt="Play" loading="eager"></button>
      </div>

      <div class="h-16 short:h-12 bg-black bg-opacity-75 flex items-center xl:px-32 md:px-12 px-2 pointer-events-auto">
        <button class="main-content-marker sm:mx-3 mx-1" v-show="videoStatus.playing" @click="handlePauseButton"><img :src="icons.playerPause" alt="Pause" title="Pause" loading="eager"></button>
        <button class="sm:mx-3 mx-1" v-show="!videoStatus.playing" @click="handlePlayButton"><img :src="icons.playerPlay" alt="Play" title="Play" loading="eager"></button>
        <button class="sm:mx-3 mx-1" :class="showSwapButton ? 'opacity-100':'opacity-0 absolute pointer-events-none'" @focus="showSwapButton = true" @click="handleSwapButton"><img :src="icons.playerSwapTracks" alt="Interact with video" title="Interact with video" loading="eager"></button>
        <button class="sm:mx-3 mx-1" @click="handleRestartButton"><img :src="icons.playerRestart" alt="Restart" title="Restart"></button>
        
        <!-- SEEK BAR -->
        <div class="flex-grow flex items-center">
          <span class="text-sm font-body-bold w-12 text-center select-none">{{ toHHMMSS(videoStatus.elapsed) }}</span>
          <div 
            class="flex-grow h-3 flex items-center cursor-pointer"
            @click="handleSeekBarClick"
            @mouseenter="handleSeekBarMouseEnter"
            @mousemove="handleSeekBarMouseMove"
            @mouseleave="handleSeekBarMouseLeave"
            @touchend="handleSeekBarMouseLeave"
          >
            <div class="h-1 w-full relative bg-ex-lightgrey">
              <!-- REAL SEEK BAR -->
              <div class="absolute bg-ex-permission-purple h-1 pointer-events-none" :style="`width: ${ (videoStatus.elapsed / videoStatus.duration * 100) }%`"></div>
              <img class="absolute transform -translate-x-1/2 -translate-y-1/3 pointer-events-none" :style="`left: ${ Math.max(1, (videoStatus.elapsed / videoStatus.duration * 100)) }%`" :src="icons.playerProgress" alt="Progress">
              <!-- GHOST SEEK BAR -->
               <div class="absolute bg-ex-permission-purple opacity-50 h-1 pointer-events-none" v-if="hoveringOnSeekBar" :style="`width: ${ (mousePositionAlongSeekBar * 100) }%`"></div>
              <img class="absolute transform -translate-x-1/2 -translate-y-1/3 opacity-50 pointer-events-none" v-if="hoveringOnSeekBar" :style="`left: ${ (mousePositionAlongSeekBar * 100) }%`" :src="icons.playerProgress" alt="Progress">
            </div>
          </div>
          <span class="text-sm font-body-bold w-12 text-center select-none">{{ toHHMMSS(videoStatus.duration) }}</span>
        </div>

        <button class="sm:mx-3 mx-1" v-show="videoSettings.captions" @click="toggleCaptions()"><img :src="icons.playerCaptions" alt="Toggle Closed Captions" title="Toggle Closed Captions" loading="eager"></button>
        <button class="sm:mx-3 mx-1" v-show="!videoSettings.captions" @click="toggleCaptions()"><img :src="icons.playerCaptionsOff" alt="Toggle Closed Captions" title="Toggle Closed Captions" loading="eager"></button>
        <button class="sm:mx-3 mx-1" v-show="!muted" @click="toggleMute()"><img :src="icons.playerVolume" alt="Toggle Mute" title="Toggle Mute" loading="eager"></button>
        <button class="sm:mx-3 mx-1" v-show="muted" @click="toggleMute()"><img :src="icons.playerVolumeOff" alt="Toggle Mute" title="Toggle Mute" loading="eager"></button>
        <button class="sm:mx-3 mx-1 relative" @click="showSettings = !showSettings" ><img :src="icons.playerSettings" alt="Quality Settings" title="Quality Settings" loading="eager">
          <transition
              enter-active-class="duration-300 ease-out"
              enter-class="opacity-0 "
              enter-to-class="opacity-100"
              leave-active-class="duration-200 ease-in"
              leave-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div class="absolute bg-black bg-opacity-75 rounded bottom-full transform -translate-y-8 md:-translate-x-8 -translate-x-16 py-2 px-4 font-body-bold text-sm" v-if="showSettings">
                <span :aria-label="`Change the video quality. Currently selected: ${videoSettings.quality} p quality.`">QUALITY</span>
                <ul class="my-2">
                  <li>
                    <button class="py-3 hover:text-white" v-for="qual in availableVideoQualitiesForThisHeight" :key="videoSettings.quality == qual ? `selected${qual}`: `${qual}`" @click="handleQualitySelection(qual)" :class="videoSettings.quality == qual ? 'text-ex-lightgrey':' text-ex-soft-purple'">     
                      <span v-if="videoSettings.quality == qual" title="selected" aria-hidden="true">● </span>
                    <span :aria-label="qual">{{`${qual}p`}}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </transition>
          </button>
        
      </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import playerPlay from '@/assets/images/player-play.svg'
  import playerPlayLarge from '@/assets/images/player-play-large.svg'
  import playerSwapTracks from '@/assets/images/player-swap-tracks.svg'

  import playerPause from '@/assets/images/player-pause.svg'
  import playerRestart from '@/assets/images/player-restart.svg'
  import playerVolume from '@/assets/images/player-volume.svg'
  import playerVolumeOff from '@/assets/images/player-volume-off.svg'
  import playerCaptions from '@/assets/images/player-captions.svg'
  import playerCaptionsOff from '@/assets/images/player-captions-off.svg'
  import playerSettings from '@/assets/images/player-settings.svg'
  import playerProgress from '@/assets/images/player-progress.svg'
  import expediaLogo from '@/assets/images/expedia-logo.svg'


  export default {
    name: 'VideoPlayerOverlay',
    props: ['preview'],
    data(){
      return {
        icons: {
          playerPlay,
          playerPlayLarge,
          playerSwapTracks,
          playerPause,
          playerRestart,
          playerVolume,
          playerVolumeOff,
          playerCaptions,
          playerCaptionsOff,
          playerSettings,
          playerProgress
        },
        expediaLogo,
        showSettings: false,
        availableVideoQualitiesForThisHeight:[],
        hoveringOnSeekBar: false,
        mousePositionAlongSeekBar: 0,
        showSwapButton: false,
      }
    },
    computed: {
      ...mapGetters(['muted','videoSettings','videoStatus','availableVideoQualities','motionDisabled','showLoadingSpinner']),
    },
    methods:{
      ...mapMutations(['setMuted','updateVideoSettings']),
      handlePlayButton(){
        this.analyticsEvent('videoplayer_play_button_clicked','features_used');
        this.$emit('play');
      },
      handlePauseButton(){
        this.analyticsEvent('videoplayer_pause_button_clicked','features_used');
        this.$emit('pause');
      },
      handleSwapButton(){
        this.analyticsEvent('videoplayer_accessible_swap_button_used','features_used');
        this.$emit('swap');
      },
      handleRestartButton(){
        this.analyticsEvent('videoplayer_restart_button_clicked','features_used');
        this.$emit('restart');
      },
      handleSkipButton(){
        this.analyticsEvent('videoplayer_skip_clicked','internal_links','SKIP VIDEO');
        this.$emit('skip');
      },
      handleSeekBarClick(event){
        this.analyticsEvent('videoplayer_seek_bar_clicked','features_used');
        const to = event.offsetX / event.target.offsetWidth;
        this.$emit('seek', {to});
      },
      handleSeekBarMouseEnter(){
        this.hoveringOnSeekBar = true;
      },
      handleSeekBarMouseMove(event){
        this.hoveringOnSeekBar = true;
        this.mousePositionAlongSeekBar = event.offsetX / event.target.offsetWidth;
      },
      handleSeekBarMouseLeave(){
        this.hoveringOnSeekBar = false;
      },
      handleQualitySelection(quality){
        this.analyticsEvent('videoplayer_quality_manually_set', 'technical', quality);
        this.updateVideoSettings({quality})
      },
      toggleMute(){
        if(this.muted){
          this.analyticsEvent('videoplayer_audio_unmute_clicked','features_used');
          this.setMuted(false);
        } else {
          this.analyticsEvent('videoplayer_audio_unmute_clicked','features_used');
          this.setMuted(true);
        }
      },
      toggleCaptions(){
        if (this.videoSettings.captions){
          this.analyticsEvent('videoplayer_captions_off_clicked','features_used');
          this.updateVideoSettings({captions: false});
        } else {
          this.analyticsEvent('videoplayer_captions_on_clicked','features_used');
          this.updateVideoSettings({captions: true});
        }
      },
      toHHMMSS (time) {
        if (typeof time != "number" || isNaN(time)) {return "00:00"}
        var sec_num = parseInt(time, 10); // don't forget the second param
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        if (hours > 0) {
          return hours+':'+minutes+':'+seconds;
        } else {
          return minutes+':'+seconds;
        }
      },
      handleResize(){
        const ideal = automaticVideoQuality(this.availableVideoQualities);
        this.availableVideoQualitiesForThisHeight = this.availableVideoQualities.filter(q => {
          return q <= ideal;
        })
      },
      revealKeyboardControls(){
        this.showSwapButton = true;
      }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    mounted(){
        this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  }


  function automaticVideoQuality(availableVideoQualities) {
    const goal = window.innerHeight;
    let closest = availableVideoQualities.reduce(function(prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
    return closest;
  }
</script>
