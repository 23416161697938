<template>
  <div id="bgvideo" v-if="video" v-show="!preloadonly" class="fixed -z-50 overflow-hidden h-screen w-screen blur-sm brightness-[40%] top-0 left-0" :class="fixed ? 'fixed':'absolute'">
    <img v-if="bgVideoDisabled" :src="posterFile" alt="Background Motion Disabled" class="min-h-full min-w-full object-cover">
    <video v-else muted autoplay playsinline :poster="posterFile" loop class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-w-[9999px]" :style="`width: ${videoWidth}px`" ref="video">
      <source :src="videoFile" type="video/mp4">
    </video>
    <audio v-if="audio" :src="audioFile" ref="audio" @play="checkVolume" autoplay loop :muted="preloadonly"></audio>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'VideoBackground',
    props: ['video','audio','fixed','preloadonly'],
    data(){
      return {
        videoWidth: 450
      }
    },
    computed:{
      ...mapGetters(['muted','backgroundAudioMuted','motionDisabled','bgVideoDisabled','videoSettings']),
      audioFile(){
        return `audio/backgrounds/${this.audio}.mp3`
      },
      videoFile(){
        return this.videoSettings.quality <= 480 ? `videos/backgrounds/${this.video}_bg_low.mp4` : `videos/backgrounds/${this.video}_bg.mp4`;
      },
      posterFile(){
        return this.videoSettings.quality <= 480 ? `videos/posters/${this.video}_bg_low.jpg` : `videos/posters/${this.video}_bg.jpg`;
      }
    },
    watch:{
      muted:{
        handler(muted){ //Muting caused by the user
          if (muted){ 
            this.off(50);
          } else {
            if(!this.backgroundAudioMuted){
              if (this.$refs.audio){
                this.$refs.audio.play();
                this.on(50);
              }
            }
          }
        },
        immediate: true
      },
      backgroundAudioMuted:{
        handler(mutedBg){ //Soft muting when entering the video part of the experience
          if (mutedBg){ 
            this.off(1000);
          } else if (!this.muted) {
            this.on(1000);
          }
        },
        immediate: true
      },
    },
    methods:{
      on(duration){
        if (!this.$refs.audio) return;
        this.setVolumeWithFade(this.$refs.audio, 1, duration)
      },
      off(duration){
        if (!this.$refs.audio) return;
        this.setVolumeWithFade(this.$refs.audio, 0, duration)
      },
      setVolumeWithFade(target, value, duration){
        const start = target.volume;
        const increase = value - target.volume;
        const startTime = Date.now();
        const fadeAudio = () => {
            let currentTime = Date.now() - startTime;
            if (currentTime > duration) currentTime = duration;
            const val = easeInOutQuad(currentTime, start, increase, duration);
            target.volume = val;
            if(currentTime < duration) {
              requestAnimationFrame(fadeAudio)
            } else {
              if (this.$refs.audio){
                this.$refs.audio.muted = target.volume <= 0;
              }
            }
          }
          fadeAudio();
      },
      checkVolume(){
        if (this.muted || this.backgroundAudioMuted || this.preloadonly){
          this.off(1)
        }
      },
      handleResize(){
        if (window.innerHeight < window.innerWidth / 16 * 9) {
          this.videoWidth = window.innerWidth;
        } else {
          this.videoWidth = window.innerHeight / 9 * 16;  
        }
      }
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    mounted(){
        this.handleResize();
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  }




function easeInOutQuad(t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
}
</script>
