import Vue from 'vue'
import Vuex from 'vuex'

import scoring from './scoring.json'
import viewingSummaryScenes from './viewingSummaryScenes.json'
import normalInstructions from './instructions.json'
import simpleInstructions from './instructions-simple.json'
import titles from './titles.json'

import thrillseeker from '@/assets/images/thrillseeker-icon.png'
import superstar from '@/assets/images/superstar-icon.png'
import champion from '@/assets/images/champion-icon.png'
import romantic from '@/assets/images/romantic-icon.png'
import freespirit from '@/assets/images/freespirit-icon.png'

Vue.use(Vuex)

const initialScores = {
  superstar: 0,
  champion: 0,
  romantic: 0,
  thrillseeker: 0,
  freespirit: 0
}

import { detectPrefersReducedMotion } from '@/helpers/index.js';

function setRandomVideoTrack(){
  const track = Math.floor(Math.random()*2);
  return track;
}

export default new Vuex.Store({
  state: {
    debugMode: false,
    muted: false,
    backgroundAudioMuted: false,
    motionDisabled: detectPrefersReducedMotion(), //Permanently like this
    bgVideoDisabled: detectPrefersReducedMotion(), //Can be toggled with the eye icon in Navbar
    currentVideoTrack: setRandomVideoTrack(),
    showLoadingSpinner: true,
    hideNav: false,
    scoring,
    availableInstructions: {
      simple: simpleInstructions,
      normal: normalInstructions
    },
    instructions: normalInstructions,
    titles,
    scores: {},
    viewingSummaryScenes,
    viewingSummaryData: {},
    availableVideoQualities: [1080,720,480,360,240],
    videoSettings: {
      volume: 1.0, //Currently not used, we just have full volume
      captions: false,
      audioType: 'stereo',
      quality: 240, //Will get set when renderer loads anyway
    },
    videoStatus: {
      playing: false,
      duration: 0,
      elapsed: 0
    },
    showMenu: false,
    personalities: [
      {
         "title":"THRILLSEEKER",
         "icon":thrillseeker,
         "link":"/thrillseeker"
      },
      {
         "title":"SUPERSTAR",
         "icon":superstar,
         "link":"/superstar"
      },
      {
         "title":"ROMANTIC",
         "icon":romantic,
         "link":"/romantic"
      },
      {
         "title":"CHAMPION",
         "icon":champion,
         "link":"/champion"
      },
      {
         "title":"FREE SPIRIT",
         "icon":freespirit,
         "link":"/freespirit"
      }
   ]
  },
  getters: {
    debugMode(state){
      return state.debugMode;
    },
    muted(state){
      return state.muted;
    },
    backgroundAudioMuted(state){
      return state.backgroundAudioMuted;
    },
    motionDisabled(state){
      return state.motionDisabled;
    },
    bgVideoDisabled(state){
      return state.bgVideoDisabled;
    },
    currentVideoTrack(state){
      return state.currentVideoTrack;
    },
    showLoadingSpinner(state){
      return state.showLoadingSpinner;
    },
    scores(state){
      return state.scores;
    },
    scoring(state){
      return state.scoring;
    },
    topPersonality(state){
      if (!Object.keys(state.scores).length) return 'superstar'; //Just so that it doesn't go nowhere in testing mode
      let result = Object.keys(state.scores).reduce(function(a, b){ return state.scores[a] > state.scores[b] ? a : b });      
      return state.scores[result] == 0 ? Object.keys(state.scores)[Math.floor(Math.random()*Object.keys(state.scores).length)] : result;
    },
    viewingSummaryScenes(state){
      return state.viewingSummaryScenes;
    },
    viewingSummaryData(state){
      let result = {};
      for (let item in state.viewingSummaryData){
        result[item] = Math.floor(state.viewingSummaryData[item]*1000)/1000;
      }
      return result;
    },
    currentInstructions(state){
      const instructionsForThisTime = state.instructions.find( ins => {
        return ins.start <= state.videoStatus.elapsed && ins.end > state.videoStatus.elapsed;
      })
      if(instructionsForThisTime){
        return state.currentVideoTrack == 0 ? instructionsForThisTime.text : instructionsForThisTime.text.slice().reverse();
      } else {
        return null;  
      }
    },
    currentTitlesElement(state){
      const titlesForThisTime = state.titles.find( ins => {
        return ins.start < state.videoStatus.elapsed && ins.end > state.videoStatus.elapsed;
      })
      if(titlesForThisTime){
        return titlesForThisTime.element;
      } else {
        return null;  
      }
    },
    hideNav(state){
      return state.hideNav;
    },
    availableVideoQualities(state){
      return state.availableVideoQualities;
    },
    videoSettings(state){
      return state.videoSettings;
    },
    videoStatus(state){
      return state.videoStatus;
    },
    showMenu(state){
      return state.showMenu;
    },
    personalities(state){
      return state.personalities;
    }
  },
  mutations: {
    toggleDebugMode(state){
      state.debugMode = !state.debugMode
    },
    setMuted(state, status){
      state.muted = status;
    },
    setBackgroundAudioMuted(state, status){
      state.backgroundAudioMuted = status;
    },
    setBgVideoDisabled(state, status){
      state.bgVideoDisabled = status;
    },
    swapVideoTrack(state){
      if (state.currentVideoTrack == 0){
        state.currentVideoTrack = 1;
      } else {
        state.currentVideoTrack = 0;
      }
    },
    setShowLoadingSpinner(state, status){
      state.showLoadingSpinner = status;
    },
    setHideNav(state, status){
      state.hideNav = status;
    },
    selectInstructions(state, payload){
      if (state.availableInstructions[payload]){
        state.instructions = state.availableInstructions[payload];
      } else {
        state.instructions = state.availableInstructions.normal;
      }
    },
    updateVideoSettings(state, payload){
      Object.assign(state.videoSettings, payload)
    },
    updateVideoStatus(state, payload){
      Object.assign(state.videoStatus, payload)
    },
    setShowMenu(state, status){
      state.showMenu = status;
    },
    addPoint(state, {personality, delta}){
      if(!state.scores[personality]){state.scores[personality] = 0.0}
      state.scores[personality] += delta;
    },
    addViewingSummaryData(state, {scene, delta, max}){
      if(!state.viewingSummaryData[scene]){state.viewingSummaryData[scene] = 0.0}
      state.viewingSummaryData[scene] = Math.min(state.viewingSummaryData[scene] + delta, max);
    },
    resetVideoPlayer(state){
      state.videoStatus.elapsed = 0;
      state.scores = { ...initialScores };
      state.viewingSummaryData = {};
      state.currentVideoTrack = setRandomVideoTrack();
    },
  },
  actions: {
  },
  modules: {
  }
})
