<template>
  <div class="absolute overflow-hidden top-0 left-0 h-full w-full flex justify-center">

    <video
      class="fixed z-[1000] h-1/2 w-1/2 bg-green-500"
      id="interactive-video"
      ref="video"
      playsinline
      v-show="false"
      @ended="handleEnded"
      @play="handlePlayed"
      @playing="handlePlayed"
      @pause="handlePaused"
      @emptied="handlePaused"
      @canplay="handleCanPlay"
      @canplaythrough="handleCanPlay"
      @seeked="handleCanPlay"
      @loadedmetadata="handleCanPlay"
    >
      <source :src="selectedVideoSource" type="video/mp4">
      <track ref="textTrack0" label="English" kind="captions" srclang="en" :src="`videos/${source}_captions_0.vtt`">
      <track ref="textTrack1" label="English" kind="captions" srclang="en" :src="`videos/${source}_captions_1.vtt`">
    </video>
    <div class="my-16 short:my-12 bg-black w-full relative">  
      <canvas id="canvasreflective" class="absolute h-full w-full object-cover blur-sm" ref="canvasreflective" width="480" height="270"></canvas>
      <div v-if="portraitMode" class="absolute h-full w-full flex flex-col justify-between">
        <div class="reflective-grad-top w-full" :style="`height:${faderSize}px;`"></div>
        <div class="reflective-grad-bot w-full" :style="`height:${faderSize}px;`"></div>
      </div>
      <div v-else class="absolute h-full w-full flex justify-between">
        <div class="reflective-grad-left h-full" :style="`width:${faderSize}px;`"></div>
        <div class="reflective-grad-right h-full" :style="`width:${faderSize}px;`"></div>
      </div>
      <canvas id="canvas" class="absolute h-full object-contain" :class="punchIn ? `w-[170%] left-[-35%] right-[-35%]`:`w-full `" @click="handleClick" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
    </div>
    <div v-if="visibleCaption" class="absolute z-50 top-0 my-16 short:my-12 left-1/2 px-2 py-1 w-screen bg-black bg-opacity-50 transform -translate-x-1/2 lg:text-3xl md:text-2xl text-xl text-center text-shadow">{{visibleCaption}}</div>
    <VideoPlayerLoadingSpinner v-if="showLoadingSpinner" @click="handleClick"/>
  </div>
</template>

<script>
  import { AudioContext } from 'standardized-audio-context';
  import { mapGetters, mapMutations } from 'vuex'

  import VideoPlayerLoadingSpinner from '@/components/VideoPlayerLoadingSpinner.vue'


  export default {
    name: 'VideoPlayerRenderer',
    components: {
      VideoPlayerLoadingSpinner
    },
    props: ['source','forcesizing'],
    data(){
      return {
        ctx: null,
        reflectivectx: null,
        faderSize: null,
        punchIn: false,
        audioCtx: null,
        canvasWidth: 1920,
        canvasHeight: 1080,
        tracks: {},
        playing: false,
        visibleCaption: null,
        frameRateThrottle: 120,
        lastFramePaint: null,
        portraitMode: null,
      }
    },
    computed: {
      ...mapGetters(['debugMode','muted','currentVideoTrack','videoSettings','videoStatus','availableVideoQualities','showLoadingSpinner']),
      selectedVideoSource(){
          return `videos/${this.source}_${this.videoSettings.audioType}_${this.videoSettings.quality}.mp4`
      },
    },
    watch:{
      selectedVideoSource(){
        this.setShowLoadingSpinner(true);
        this.$refs.video.load()
        // if (this.playing){
        //   this.$refs.video.play().then(()=>{
        // }).catch(err => {
        //   console.log(err)
        // })
        // }
      },
      videoStatus: {
        handler(status){
          if (!status.duration || !status.elapsed){
            return
          }
          if (status.duration - status.elapsed < 3.5){ //Send ended signal if video gets to within 4s of end.
            this.handleEnded();
          }
        },
        deep: true
      },
      videoSettings: {
        handler(settings){
          this.handleQualityChange(settings.quality);
        },
        deep: true,
        immediate: true
      }
    },
    methods:{
      ...mapMutations(['updateVideoStatus','setShowLoadingSpinner']),
      //USER INITIATED EVENTS
      handleClick(){
        this.analyticsEvent('videoplayer_click_to_swap','features_used')
        this.$emit('swap');
      },
      handleResize(){
        this.calculatePunchIn();
        this.calculateFaderSizing();
      },
      handleQualityChange(newQuality){
        if (newQuality != this.canvasHeight){
          this.canvasHeight = newQuality;
          const formats = {
            1080: 1920,
            720: 1280,
            480: 854,
            360: 640,
            240: 426
          } //NOTE: Be careful around here... this is very not error checking!
          this.canvasWidth = formats[newQuality];
          if (this.$refs.canvas){
            this.$refs.canvas.width = this.canvasWidth;
            this.$refs.canvas.height = this.canvasHeight;
          }
        }
      },

      //CSS ELEMENTS
      calculateFaderSizing(){
        if (!this.$refs.canvas){
          return;
        }
        const h = this.$refs.canvas.clientHeight;
        const w = this.$refs.canvas.clientWidth;
        if (!h || !w){
          return;
        }
        if (h / w > 9 / 16){
          this.portraitMode = true;
          this.faderSize  = ((h - 9 * w / 16) / 2) + 5;
        } else {
          this.portraitMode = false;
          this.faderSize  = ((w - 16 * h / 9) / 2) + 5;
        }
      },
      calculatePunchIn(){
        this.punchIn = window.innerWidth < 600;
      },

      //VIDEO ACTIONS
      play(){
        this.audioCtx.resume();
        this.$refs.video.play();
        this.playing = true;
        this.update();
      },
      pause(){
        this.audioCtx.suspend();
        this.$refs.video.pause();
        this.playing = false;
      },
      seek(to) {
        this.$refs.video.pause();
        this.$refs.video.currentTime = to;
        this.setShowLoadingSpinner(true);
        this.updateVideoStatus({
          duration: this.getDuration(),
          elapsed: to,
        });
      },

      //VIDEO EVENTS
      handlePlayed(){
        this.updateVideoStatus({playing: true});
        this.setShowLoadingSpinner(false);
      },
      handlePaused(){
        this.updateVideoStatus({playing: false});
      },
      handleEnded(){
        if (this.playing){
          this.playing = false;
          this.$emit('ended');
        }
      },
      handleCanPlay(){
        this.setShowLoadingSpinner(false);
        if (this.videoStatus.elapsed && this.$refs.video && this.$refs.video.currentTime == 0){
          this.seek(this.videoStatus.elapsed);
        }
        if (this.playing){
          this.$refs.video.play();
        } else {
          this.update(); //just do this to show the thumbnail
        }
      },

      //VIDEO STATUS
      refreshVideoStatus(){   
        const duration = this.getDuration();
        const elapsed = this.getTime(); 
        if (duration && elapsed){    
          this.updateVideoStatus({
            duration,
            elapsed,
          })
        }
      },

      //INITIALIZATIONS
      
      async initVideo(){
        return new Promise((resolve,reject) => {
          let count = 0;
          const selectVideoElement = () => {
            const el = document.getElementById(`interactive-video`);
            if (el) {
              this.$refs.video = el;
              resolve(el);
            } else if (count > 200) {
              reject();
            } else {
              count += 1;
              setTimeout(selectVideoElement, 50);
            }
          }
          selectVideoElement();
        });
      },
      
      initCanvas(){
        this.ctx = this.$refs.canvas.getContext("2d", { alpha: false });
        this.reflectivectx = this.$refs.canvasreflective.getContext("2d", { alpha: false });
        new ResizeObserver(this.handleResize).observe(this.$refs.canvas); //Add this to get better reliability of resize functions. Left the old method in as a fallback
      },
      initAudio(){
          this.audioCtx = new AudioContext(); //{sampleRate: 48000} might help here, or it might have made things worse
          const source = this.audioCtx.createMediaElementSource(this.$refs.video);

          this.tracks.track1 = this.audioCtx.createGain();
          this.tracks.track2 = this.audioCtx.createGain();

          if (this.videoSettings.audioType == 'quad'){

            const splitter = this.audioCtx.createChannelSplitter(4);
            source.connect(splitter);

            const track1 = this.audioCtx.createChannelMerger(2);
            splitter.connect( track1, 0, 0 );
            splitter.connect( track1, 1, 1 );

            const track2 = this.audioCtx.createChannelMerger(2);
            splitter.connect( track2, 2, 0 );
            splitter.connect( track2, 3, 1 );

            track1.connect(this.tracks.track1);
            track2.connect(this.tracks.track2);

          } else if (this.videoSettings.audioType == 'stereo') {
            
            const splitter = this.audioCtx.createChannelSplitter(2);
            source.connect(splitter);
            splitter.connect( this.tracks.track1, 0 );
            splitter.connect( this.tracks.track2, 1 );
            
          }
  
          this.tracks.track1.connect(this.audioCtx.destination);
          this.tracks.track2.connect(this.audioCtx.destination);
      },   

      //RENDERINGS   
      renderVideos(){
        if (!this.ctx || !this.reflectivectx) {return}
        let sourceWidth = this.$refs.video.videoWidth;
        let sourceHeight = this.$refs.video.videoHeight;
        let sourceX = 0;
        let sourceY = 0;

        let drawWidth = this.canvasWidth;
        let drawHeight = this.canvasHeight;
        let drawX = 0;
        let drawY = 0;

        
        //A-B VIDEO MODE SETUP
        sourceHeight = Math.floor((this.$refs.video.videoHeight / 2)); //We take 2 off for safety margin. Must result in an even number.
        if (this.currentVideoTrack == 1){
          sourceY = sourceHeight; //Move past the safety margin
        }

        //DRAW MAIN VIDEO
        this.ctx.drawImage(this.$refs.video, sourceX, sourceY, sourceWidth, sourceHeight, drawX, drawY, drawWidth, drawHeight);
        
        //DRAW A VERY QUICK FIX FOR THE PIXEL BLEED
        this.ctx.fillStyle = 'rgba(0,0,0,0.95)';
        this.ctx.fillRect(0,0,drawWidth,1);
        this.ctx.fillRect(0,drawHeight - 1,drawWidth,1);
        
        //DRAW REFLECTIVE FILLER
        this.reflectivectx.drawImage(this.$refs.canvas, 0, 0, drawWidth, drawHeight, 0, 0, 480, 270)
     
      },
      renderAudio(){
        let volume;
        if (this.currentVideoTrack == 0){
          volume = [this.videoSettings.volume,0];
        } else {
          volume = [0,this.videoSettings.volume]
        }
        if (this.muted){
          this.tracks.track1.gain.setValueAtTime(0, this.audioCtx.currentTime);
          this.tracks.track2.gain.setValueAtTime(0, this.audioCtx.currentTime);
        } else {
          this.tracks.track1.gain.setValueAtTime(volume[0], this.audioCtx.currentTime);
          this.tracks.track2.gain.setValueAtTime(volume[1], this.audioCtx.currentTime);
        }
      },
      renderTextTracks(){
        if (this.videoSettings.captions){
          this.$refs.textTrack0.track.mode = this.currentVideoTrack == 0 ? 'showing':'hidden';
          this.$refs.textTrack1.track.mode = this.currentVideoTrack == 1 ? 'showing':'hidden';
          if (!this.$refs.video || !this.$refs.video.textTracks || !this.$refs.video.textTracks[this.currentVideoTrack] || !this.$refs.video.textTracks[this.currentVideoTrack].activeCues || !this.$refs.video.textTracks[this.currentVideoTrack].activeCues[0]) {
            this.visibleCaption = false;
          } else {
            this.visibleCaption = this.$refs.video.textTracks[this.currentVideoTrack].activeCues[0].text;
          }
        } else {
          this.visibleCaption = false;
        }
      },
      update(){
        if (this.playing){
          requestAnimationFrame(this.update);
        }

        if (this.$refs.video){
          this.renderVideos();
          this.renderTextTracks();
          if (this.audioCtx) this.renderAudio();
          this.refreshVideoStatus();

          if (!this.lastFramePaint || performance.now() - this.lastFramePaint > 100){ //100 should put us clear of all framerates.
            this.lastFramePaint = performance.now();
            return;
          }
          const delta = Math.round((((performance.now() - this.lastFramePaint)/1000) + Number.EPSILON) * 1000) / 1000;
          this.$emit('point', { time: this.getTime(), track: this.currentVideoTrack, delta });
          this.lastFramePaint = performance.now();
        }
      },
      

      //VIDEO STATUS
      getTime() {
        return this.$refs.video.currentTime;
      },
      getDuration() {
        return this.$refs.video.duration;
      },
    },
    async mounted(){
      await this.initVideo();
      this.initAudio();
      this.initCanvas();
      this.handleResize();
    },
    created(){
        window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
  }


</script>
