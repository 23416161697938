<template>
  <footer class="flex md:justify-between justify-center items-center mt:pt-6 pt-4 md:pb-6-safe pb-2-safe md:flex-nowrap flex-wrap text-xs mx-5">
    
    <div class="flex md:mb-0 mb-3 md:mr-5 md:ml-0 ml-2 flex md:justify-start justify-center items-center order-2">
      <div
      class="flex-shrink-0"
      >
        <img :src="lasVegasLogo" alt="Las Vegas Logo" class="lg:h-16 md:h-12 sm:h-10 h-8" loading="lazy"/>
      </div>
      <span class="lg:ml-6 md:ml-4 ml-2">© 2022 Expedia, Inc., an<span><img :src="expediaGroupLogo" aria-label="Expedia Group" alt="Expedia Group" class="inline mx-1"/></span>company. All Rights&nbsp;Reserved.</span>
    </div>

    <ul class="flex order-3 md:w-min w-full justify-center flex-wrap lg:flex-nowrap">
      <li class="ml-1">
        <a href="https://www.expedia.com/lp/lg-privacypolicy?mcicid=cp.vegas" @click="analyticsEvent('footer_link_clicked', 'outbound_links', 'https://www.expedia.com/lp/lg-privacypolicy?mcicid=cp.vegas')" target="_blank" title="Privacy Policy">Privacy&nbsp;Policy</a>
      </li>
      <li class="ml-1" aria-hidden="true">·</li>
      <li class="ml-1">
        <a href="https://www.expedia.com/lp/lg-legal?mcicid=cp.vegas" @click="analyticsEvent('footer_link_clicked', 'outbound_links', 'https://www.expedia.com/lp/lg-legal?mcicid=cp.vegas')" target="_blank" title="Terms of Use">Terms&nbsp;of&nbsp;Use</a>
      </li>
      <li class="ml-1 hidden lg:inline" aria-hidden="true">·</li>
      <li class="ml-1">
        <a href="https://www.expedia.com/dnsmpi?mcicid=cp.vegas" @click="analyticsEvent('footer_link_clicked', 'outbound_links', 'https://www.expedia.com/dnsmpi?mcicid=cp.vegas')" target="_blank" title="Do not sell my personal information">Do&nbsp;not&nbsp;sell&nbsp;my&nbsp;personal&nbsp;information</a>
      </li>
    </ul>

    <ul class="flex align-stretch md:ml-5 flex-shrink-0 md:order-4 order-1 md:w-auto w-full justify-center md:mb-0 mb-3">
      <li v-for="social in socials" :key="social.name" class="ml-2 mr-2 first:ml-0 last:mr-0 h-6">
        <a :href="social.link" target="_blank" @click="analyticsEvent('footer_link_clicked', 'outbound_links', social.link)" :title="`Las Vegas ${social.name}`" class="flex flex-col h-full justify-center items-center ">
          <img :src="social.icon" :alt="social.name" class="w-6">
        </a>
      </li>
    </ul>

  </footer>
</template>

<script>
import expediaGroupLogo from '@/assets/images/expedia-group-logo.svg'
import lasVegasLogo from '@/assets/images/las-vegas-logo.svg'
import socialFacebook from '@/assets/images/social-facebook.svg'
import socialInstagram from '@/assets/images/social-instagram.svg'
import socialYoutube from '@/assets/images/social-youtube.svg'

export default {
  name: "FooterBar",
  data(){
    return {
      expediaGroupLogo,
      lasVegasLogo,
      socials: [
        { 
          icon: socialFacebook,
          name: "Facebook",
          link: "https://www.facebook.com/lasvegas/"
        },
        {
          icon: socialInstagram,
          name: "Instagram",
          link: "https://www.instagram.com/vegas/"
        },
        {
          icon: socialYoutube,
          name: "Youtube",
          link: "https://www.youtube.com/user/lasvegas"
        }
      ]
    }
  }
};
</script>