<template>  
  <header class="backdrop-blur-lg bg-black not-support-backdrop-filter:backdrop-blur-fallback flex justify-center w-full duration-500" :class="showMenu ? 'bg-opacity-50':'bg-opacity-0'">
    <div class="flex justify-between max-w-screen-3xl w-full xxs:px-5 px-2">

      <!-- LEFT SIDE -->
      <div class="flex justify-start items-center h-20 short:h-16 relative">
        <a href="https://expedia.com?mcicid=cp.vegas" @click="analyticsEvent('nav_expedia_logo_clicked', 'outbound_links', 'https://expedia.com')" title="Go to Expedia website" aria-label="Go to Expedia website" target="_blank">
          <img class="sm:h-8 h-6" :src="expediaLogo" alt="Expedia Logo" />
        </a>
        <transition
            mode="out-in"
            enter-active-class="duration-500"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-500"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
        <div v-show="showMenu">
          <img class="sm:h-8 h-6 ml-4 pl-4 border-l border-l-ex-charcoal" :src="lasVegasLogo" alt="Las Vegas Logo" />
        </div>
        </transition>
      </div>

      <!-- RIGHT SIDE -->
      <div class="flex justify-end items-center h-20 short:h-16">
        <ControlMotion class="sm:mr-5 mr-3" v-if="showMotionButton"/>
        <ControlVolume class="sm:mr-5 mr-3" v-if="showVolumeButton"/>
        <a href="https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva?mcicid=cp.vegas" v-clickfeedback="'btn-purple-hollow'" @click="analyticsEvent('nav_start_planning_clicked', 'outbound_links', 'https://www.expedia.com/lp/b/ms-b-dmo-exp-us-lvcva')" title="Start planning your trip to Las Vegas" target="_blank" class="btn-purple-hollow sm:text-xl text-sm whitespace-nowrap" :class="showMenuButton ? 'sm:mr-5 mr-3':''" v-if="showStartPlanning">START PLANNING</a>

        <ControlMenu class="mr-1" v-if="showMenuButton"/>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ControlVolume from '@/components/ControlVolume.vue'
  import ControlMotion from '@/components/ControlMotion.vue'
  import ControlMenu from '@/components/ControlMenu.vue'

  import expediaLogo from '@/assets/images/expedia-logo.svg'
  import lasVegasLogo from '@/assets/images/las-vegas-logo.svg'

  export default {
    name: "NavBar",
    components:{
      ControlMotion,
      ControlVolume,
      ControlMenu
    },
    data(){
      return {
        expediaLogo,
        lasVegasLogo
      }
    },
    computed: {
      ...mapGetters(['showMenu']),
      showStartPlanning(){
        if(["/","/choose"].includes(this.$route.path.toLowerCase()) && !this.showMenu) {
          return true
        } else {
          return false
        }
      },
      showMenuButton(){
        if(['/superstar','/thrillseeker','/romantic','/champion','/freespirit'].includes(this.$route.path.toLowerCase()) ) {
          return true
        } else {
          return false
        }
      },
      showVolumeButton(){
        if(['/superstar','/thrillseeker','/romantic','/champion','/freespirit','/'].includes(this.$route.path.toLowerCase()) ) {
          return true
        } else {
          return false
        }
      },
      showMotionButton(){
        if(['/superstar','/thrillseeker','/romantic','/champion','/freespirit','/'].includes(this.$route.path.toLowerCase()) ) {
          return true
        } else {
          return false
        }
      },
      
    }
  }
</script>