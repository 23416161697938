<template>
  <div id="app" ref="app" class="app text-white mb-0-safe overscroll-none overflow-hidden" :class="showMenu ? 'overflow-hidden h-screen':'flex flex-col items-center'"  :style="fixedHeightPage ? `min-height: ${distanceToBottomOfScreen}px; `: 'min-height: 100vh'">
    <button class="fixed z-50" :class="showSkipLink ? 'opacity-100':'opacity-0'" @focus="showSkipLink = true" @blur="showSkipLink = false" @click="skipToMainContent" tabindex="0">Skip to main content</button>
    <transition
        mode="out-in"
        enter-active-class="duration-500 ease-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-500 ease-in"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
      <NavBar v-show="!hideNav" class="fixed top-0 left-0 z-40 w-full"/>
    </transition>
    <MainMenu class="fixed top-0 z-40 w-full sm:max-w-lg"/>

    <div class="w-full max-w-screen-3xl flex flex-col flex-grow" :class="hideNav ? '':'mt-20 short:mt-16'">
      <!-- This transition will be displayed when navigating between different pages or routes -->
      <!--Bizarrely if you put a translate in enter-class you get a weird bug where it's sometimes rendering the page off to the left.-->
      <transition
        mode="out-in"
        enter-active-class="duration-500 ease-out"
        enter-class="opacity-0" 
        enter-to-class="opacity-100"
        leave-active-class="duration-500 ease-in"
        leave-class="opacity-100 translate-x-0"
        :leave-to-class="motionDisabled ? 'opacity-0':'opacity-0 -translate-x-full'"
      >
        <router-view :key="$route.fullPath" @click.native="closeMenu" tabIndex="-1"></router-view>
      </transition>
      <FooterBar v-show="!hideNav" ref="footer" class="max-w-screen-3xl"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { detectCanHandleMultiChannelAudio } from '@/helpers/index.js'


import NavBar from "@/components/NavBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import MainMenu from "@/components/MainMenu.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterBar,
    MainMenu
  },
  data(){
    return {
      showSkipLink: false,
      distanceToBottomOfScreen: window.innerHeight || 600, //Arbitrary
      previousWindowHeight: null,
      resizeDebouncer: null,
      howMuchIsATrivialChange: 80, //iPhone Address bar is 77px
      hasDoneATrivialResize: false, //To prevent constant resizing as safari ios address bar pings in and out
    }
  },
  computed: {
      ...mapGetters(['showMenu','hideNav','muted','backgroundAudioMuted','motionDisabled','bgVideoDisabled','availableVideoQualities']),
      fixedHeightPage(){
        if(['/analyzing','/choose','/'].includes(this.$route.path.toLowerCase()) ) {
          return true
        } else {
          return false
        }
      }
  },
  watch:{
    hideNav(){
      if (this.fixedHeightPage){
        this.$nextTick(()=>{
          this.resizeActions(true);
        })
      }
    }
  },
  methods: {
    ...mapMutations(['setShowMenu','updateVideoSettings','selectInstructions']),
    closeMenu(){
      this.setShowMenu(false);
    },
    skipToMainContent(){
      const target = document.querySelector('.main-content-marker')
      target.focus()
    },
    handleResize(){
      if (this.fixedHeightPage){
        clearTimeout(this.resizeDebouncer);
        this.resizeDebouncer = setTimeout(()=>{this.resizeActions()},100);
      }
    },
    resizeActions(force = false){
      const actualHeight = Math.min(window.innerHeight, window.outerHeight);
      const footerHeight = this.hideNav ? 0 : this.$refs.footer.$el.clientHeight;
      const changeInHeight = Math.abs(this.previousWindowHeight - actualHeight);
      if (Math.abs(changeInHeight) < this.howMuchIsATrivialChange){
        if (this.hasDoneATrivialResize && !force){
          return;
        }
          this.hasDoneATrivialResize = true;
      }
      this.previousWindowHeight = actualHeight;
      if (actualHeight < 560){
        this.distanceToBottomOfScreen = actualHeight + footerHeight - 8;
      } else {
        this.distanceToBottomOfScreen = actualHeight;
      }
      this.scrollToTop();
    },
  },
  created(){
      window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  mounted(){
    const quality = automaticVideoQuality(this.availableVideoQualities);
    this.updateVideoSettings({quality});
    
    const audioType = detectCanHandleMultiChannelAudio() ? 'quad':'stereo';
    this.updateVideoSettings({audioType});

    if (this.$route.query.instructions){
      this.selectInstructions(this.$route.query.instructions)   
    }

    this.analyticsEvent('videoplayer_quality_automatically_set', 'technical', quality);

    this.$nextTick(()=>{
      this.handleResize();
    })
    setTimeout(this.handleResize, 300); //scoops up when a device might resize before properly reporting the view height.

  }
};

 function automaticVideoQuality(availableVideoQualities) {
    const goal = window.innerHeight;
    let closest = availableVideoQualities.reduce(function(prev, curr) {
      return (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev);
    });
    return closest;
  }


</script>

<style>

</style>
