import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-3JC3MKMDNP" }, //Production: G-3JC3MKMDNP, Development: G-4CX10BSE1T
  appName: 'Las Vegas',
}, router);

Vue.mixin({
  methods: {
    analyticsEvent(action, event_category = 'other', event_label = '', additionals){
      this.$gtag.event(action, {
        event_category,
        event_label,
        ...additionals
      })
    },
    analyticsVirtualPageView(page_title){
      this.$gtag.pageview({
        page_title
      })
    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
  }
})

Vue.directive('clickfeedback', {
  inserted: ( el, binding ) => {
    el.clickFeedback = function (event) {
      const target = event.currentTarget;
      target.classList.add(`${binding.value}__clickfeedback`)
      setTimeout(()=>{
        target.classList.remove(`${binding.value}__clickfeedback`)
      },100)
    }
    el.addEventListener('click', el.clickFeedback)
    el.addEventListener('touchstart', el.clickFeedback)
  },
  unbind: el => {
    el.removeEventListener('click', el.clickFeedback)
    el.removeEventListener('touchstart', el.clickFeedback)
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


console.log('video 8, web 12.6 - fixed duration bug')