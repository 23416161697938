
<template>
    <!-- BACKGROUND DIMMER -->
    <transition
      enter-active-class="duration-300 ease"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-300 ease"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-80 flex items-center justify-center z-50 xxs:p-4">
      <!-- MODAL -->
        <transition
          enter-active-class="duration-500 ease-out"
          :enter-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-y-full'"
          enter-to-class="opacity-100 transform translate-y-0"
          leave-active-class="duration-500 ease-in"
          leave-class="opacity-100 transform translate-y-0"
          :leave-to-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-y-full'"
        >
          <div class="bg-black border border-ex-charcoal rounded-xl flex flex-col xs:items-center short:items-stretch lg:px-24 lg:py-16 sm:px-12 xs:px-8 xxs:px-4 px-2 py-8 veryshort:py-4" aria-modal="true">
            <h1 class="sm:text-7xl text-6xl tracking-headline font-headline-bold text-center lg:mb-8 mb-4 leading-[4rem]">YOU'RE IN <span class="text-ex-soft-purple">CONTROL</span></h1>
            
            <div class="flex flex-col items-center veryshort:flex-row veryshort:justify-between" v-show="showClickInstruction">
              <img :src="instructionsTapClick" rel="preload" class="lg:h-20 h-16 lg:mb-8 mb-4 mx-1" alt="Finger Tapping Icon">
              <p class="font-body-medium lg:text-xl text-lg xs:max-w-[450px] max-w-[70%] short:max-w-[300px] text-center veryshort:text-left lg:mb-8 mb-4"><span class="text-ex-soft-purple font-body-extra-bold">Click</span> at any time to<span class="text-ex-soft-purple font-body-extra-bold"> change</span> your adventure. Your choices will reveal your<span class="text-ex-soft-purple font-body-extra-bold"> Las Vegas</span> persona.</p>
            </div>
            
            <div class="flex flex-col items-center veryshort:flex-row veryshort:justify-between" v-show="showTapInstruction">
              <img :src="instructionsTapClick" rel="preload" class="lg:h-20 h-16 lg:mb-8 mb-4" alt="Finger Tapping Icon">
              <p class="font-body-medium lg:text-xl text-lg xs:max-w-[450px] max-w-[70%] short:max-w-[300px] text-center veryshort:text-left lg:mb-8 mb-4"><span class="text-ex-soft-purple font-body-extra-bold">Tap</span> at any time to<span class="text-ex-soft-purple font-body-extra-bold"> change</span> your Las&nbsp;Vegas experience.</p>
            </div>
            
            <div class="flex flex-col items-center veryshort:flex-row veryshort:justify-between" v-show="showRotateInstruction">
              <img :src="instructionsRotate" rel="preload" class="lg:h-20 h-16 lg:mb-8 mb-4" alt="Rotation Icon">
              <p class="font-body-medium lg:text-xl text-lg xs:max-w-[450px] max-w-[70%] short:max-w-[300px] text-center veryshort:text-left lg:mb-8 mb-4">Turn your phone sideways to landscape mode.</p>
            </div>
           
            <button class="btn-purple xs:w-96 rounded" ref="cta" v-clickfeedback="'btn-purple'" @click="begin" aria-label="got it">GOT IT!</button>
          </div>
        </transition>
        
      </div>
    </transition> 
</template>

<script>
  import { mapGetters } from 'vuex'

  import { detectMobile, detectPortraitMode, detectSmallDevice } from '@/helpers/index.js'

  import instructionsTapClick from '@/assets/images/instructions-tap-purple.svg'
  import instructionsRotate from '@/assets/images/instructions-rotate.svg'

    export default {
        name: 'VideoPlayerIntroModal',
        data(){
          return {
            instructionsTapClick,
            instructionsRotate,
          }
        },
        computed:{
            ...mapGetters(['motionDisabled']),
            showClickInstruction(){
                return !detectMobile();
            },
            showTapInstruction(){
                return detectMobile();
            },
            showRotateInstruction(){
              // eslint-disable-next-line
              const show = detectPortraitMode() && detectSmallDevice() && detectMobile();
              return false; //disabled feature in this version.  
            }
        },
        methods: {
            begin(){
              this.analyticsEvent('instructions_acknowledge_clicked', 'internal_links', 'GOT IT!');
              this.$emit('begin');
            }
        }
    }

</script>

<style>
.finger-animate {
        transform-box: fill-box;
        transform-origin: 50% 50%;
        animation: pulse 1.5s linear infinite;
        color: #FFFFFF;
        fill: #FFFFFF;
    }

    @keyframes pulse {
        0% { transform: scale(100%); filter: brightness(300%); }
        10% { transform: scale(120%); }
        50% { transform: scale(100%); }
    }
</style>