<template>
  <div>
    <div v-if="debugMode" class="fixed z-50 top-0 right-0 opacity-50 bg-green-800">{{scores}}</div>
    <VideoPlayerRenderer ref="VideoPlayerRenderer" @point="handlePoint" @swap="handleSwap" @ended="handleVideoEnded" source="v8" />
    <VideoPlayerInstructions @swap="handleSwap" :nudge="true"/>
    <!-- <VideoPlayerTitles /> -->
    <VideoPlayerOverlay @play="handlePlayButton" @pause="handlePauseButton" @restart="handleRestartButton" @swap="handleSwap" @skip="handleSkipVideo" @seek="handleSeekBar" ref="VideoPlayerOverlay"/>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex'

  import VideoPlayerRenderer from '@/components/VideoPlayerRenderer.vue'
  import VideoPlayerInstructions from '@/components/VideoPlayerInstructions.vue'
  // import VideoPlayerTitles from '@/components/VideoPlayerTitles.vue'
  import VideoPlayerOverlay from '@/components/VideoPlayerOverlay.vue'
  

  export default {
    name: 'VideoPlayer',
    components:{
      VideoPlayerRenderer,
      VideoPlayerInstructions,
      // VideoPlayerTitles,
      VideoPlayerOverlay,
    },
    computed:{
       ...mapGetters(['debugMode','scoring','scores','topPersonality','videoStatus','viewingSummaryScenes','viewingSummaryData']),
    },
    methods:{
      ...mapMutations(['swapVideoTrack', 'addPoint','resetVideoPlayer','toggleDebugMode','addViewingSummaryData']),
      play(){
        this.$refs.VideoPlayerRenderer.play();
      },
      pause(){
        this.$refs.VideoPlayerRenderer.pause();
      },
      seek(to){
        this.$refs.VideoPlayerRenderer.seek(to);
      },
      handleSwap(){
        this.analyticsEvent('videoplayer_swap_track','features_used');
        this.swapVideoTrack();
      },
      handleKeyPress(e){
        if (e.key == "q" && e.ctrlKey){
          this.handleVideoEnded();
        } else if (e.key == "d" && e.ctrlKey){
          this.toggleDebugMode();
        } else if (e.key == "Tab") {
          this.$refs.VideoPlayerOverlay.revealKeyboardControls()
        }
      },
      handlePlayButton(){
        this.play();
      },
      handlePauseButton(){
        this.pause();
      },
      handleRestartButton(){
        this.seek(0);
      },
      handleSeekBar({to}){
        if (this.videoStatus.duration && to){
          this.seek(to * this.videoStatus.duration);
        }
      },
      handlePoint({track, time, delta}){
        for (let entry of this.scoring[track]){
          if (entry.start <= time && entry.end > time) {
            for (let p of entry.personalities) {
              this.addPoint({personality: p, delta});
            }
          }
        }
        for (let entry of this.viewingSummaryScenes[track]){
          if (entry.start <= time && entry.end > time) {
            const max = entry.end - entry.start;
            this.addViewingSummaryData({scene: entry.label, delta, max});
          }
        }
      },
      handleVideoEnded(){
        this.analyticsEvent("calculated_persona", "engagement", this.topPersonality)
        this.analyticsEvent("viewing_summary", "engagement", null, this.viewingSummaryData)
        this.$router.push(`/analyzing`);
      },
      handleSkipVideo(){
        this.$router.push('/choose')
      }
    },
    mounted() {
      this.resetVideoPlayer();
      this.scrollToTop();
    },
    created() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    destroyed() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
  }
</script>
