import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/superstar',
    name: 'Persona: Superstar',
    props: { personality: 'superstar'},  
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue')
  },
  {
    path: '/thrillseeker',
    name: 'Persona: Thrillseeker',
    props: { personality: 'thrillseeker'},  
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue')
  },
  {
    path: '/champion',
    name: 'Persona: Champion',
    props: { personality: 'champion'},  
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue')
  },
  {
    path: '/romantic',
    name: 'Persona: Romantic',
    props: { personality: 'romantic'},  
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue')
  },
  {
    path: '/freespirit',
    name: 'Persona: Free Spirit',
    props: { personality: 'freespirit'},  
    component: () => import(/* webpackChunkName: "results" */ '../views/ResultsView.vue')
  },

  {
    path: '/choose',
    name: 'Choose',
    component: () => import(/* webpackChunkName: "choose" */ '../views/ChooseView.vue')
  },

  {
    path: '/analyzing',
    name: 'Analyzing',
    component: () => import(/* webpackChunkName: "analyzing" */ '../views/AnalyzingView.vue')
  },

  {
    path: '/preview', //supply file name as an argument
    name: 'Preview',
    component: () => import(/* webpackChunkName: "preview" */ '../views/PreviewView.vue')
  },

  {
    path: '/',
    name: 'Landing',
    component: MainView,
  },

  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
