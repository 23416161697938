<template>
  <main id="main" ref="main" class="flex-grow flex flex-col relative main-content-marker">
    <!-- LANDING -->
    <VideoBackground v-if="step == 'landing' || step == 'instructions'" video="landing" audio="landing" :fixed="true" key="landing-bg" />
    <IntroductionContent v-if="step == 'landing' || step == 'instructions'" @begin="beginUserGuide" key="landing" />

    <!-- GUIDE -->
    <VideoPlayerUserGuide v-show="step == 'instructions'" @begin="beginVideo" key="instructions" ref="instructions" />
    
    <!-- VIDEO -->
    <VideoPlayer v-show="step == 'video'" ref="videoPlayer" key="video"/>
    <!-- VEGAS SIGN PRELOAD -->
    <img :src="loadingSignDark" v-show="step == 'video'" class="absolute h-0 w-0">
    <img :src="loadingSignIlluminated" v-show="step == 'video'" class="absolute h-0 w-0">

    <!-- MAKE SURE IT LOADS THE BOLD FONT IMMEDIATELY -->
    <p class="absolute opacity-0 font-body-extra-bold" aria-hidden="true">Click change Las Vegas</p>
  </main>
</template>

<script>
import { mapMutations } from 'vuex'

import IntroductionContent from '@/components/IntroductionContent.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import VideoPlayerUserGuide from '@/components/VideoPlayerUserGuide.vue'
import VideoBackground from '@/components/VideoBackground.vue'

import loadingSignDark from '@/assets/images/loading-sign-dark.svg'
import loadingSignIlluminated from '@/assets/images/loading-sign-illuminated.svg'

export default {
  name: 'MainView',
  components: {
    IntroductionContent,
    VideoBackground,
    VideoPlayerUserGuide,
    VideoPlayer
  },
  data(){
    return {
      step: 'landing', //landing, instructions, video
      distanceToBottomOfScreen: 0,
      loadingSignDark,
      loadingSignIlluminated,
    }
  },
  methods: {
    ...mapMutations(['setHideNav','setBackgroundAudioMuted']),
    beginUserGuide(){
      this.step = 'instructions';
      this.setBackgroundAudioMuted(true);
      this.analyticsVirtualPageView('Instructions')
    },
    beginVideo(){
      this.step = 'video';
      this.$refs.videoPlayer.play();
      this.setHideNav(true);
      this.setBackgroundAudioMuted(true);
      this.analyticsVirtualPageView('Film: Main')
    },
    
  },
  mounted(){
      this.setHideNav(false);
      this.scrollToTop();
  },
}
</script>
