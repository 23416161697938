<template>
    <button class="border-2 border-ex-soft-purple rounded-full h-12 w-12 flex items-center justify-center" title="Show menu" @click="toggleShowMenu()">
        <transition
            mode="out-in"
            enter-active-class="duration-200 ease-out"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <img :key="menuIcon" :src="menuIcon" alt="Menu">
        </transition>
        <img :key="notMenuIcon" :src="notMenuIcon" class="absolute invisible">
      </button>
</template>

<script>
import menuClosedIcon from '@/assets/images/menu-icon-lines.svg'
import menuOpenIcon from '@/assets/images/menu-icon-cross.svg'

import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'ControlMenu',
    data(){
        return {
            menuOpenIcon,
            menuClosedIcon
        }
    },
    computed: {
        ...mapGetters(['showMenu']),
        menuIcon(){
            return this.showMenu ? menuOpenIcon:menuClosedIcon;
        },
        notMenuIcon(){
            return !this.showMenu ? menuOpenIcon:menuClosedIcon;
        }
    },
    methods: {
        ...mapMutations(['setShowMenu']),
        toggleShowMenu(){
            if(this.showMenu){
                this.analyticsEvent('nav_menu_close_clicked','features_used');
                this.setShowMenu(false)
            } else {
                this.analyticsEvent('nav_menu_open_clicked','features_used');
                this.setShowMenu(true)
            }
        }
    }
}
</script>