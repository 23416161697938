<template>
 <transition
    v-if="videoStatus.playing"
    mode="out-in"
    enter-active-class="duration-300 ease-out"
    :enter-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-y-12'"

    enter-to-class="opacity-100 transform translate-x-0"
    leave-active-class="duration-300 ease-in"
    leave-class="opacity-100 transform translate-x-0"
    :leave-to-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-y-12'"
  >   
    <div class="absolute sm:bottom-20 bottom-[4.5rem] short:bottom-14 left-0 right-0 flex justify-center items-center duration-500 pointer-events-none" v-if="currentInstructions">
      <transition-group
        enter-active-class="duration-0"
        enter-class="absolute opacity-0"
        enter-to-class=""
        leave-active-class="duration-0"
        leave-class=""
        leave-to-class="absolute opacity-0"
        move-class="duration-300"
        tag="span"
        class="flex flex-col sm:flex-row justify-center items-center"
      >
      <span class="btn-solid-light-purple text-lg short:py-1 w-[270px] pointer-events-auto select-none" :key="JSON.stringify(currentInstructions[0])">{{currentInstructions[0]}}</span>
      <span class="sm:text-4.5xl text-4xl tracking-headlinewide font-headline-bold text-shadow mx-3 select-none" key="OR"> OR </span>
      <span role="button" class="btn-purple text-lg short:py-1 w-[270px] pointer-events-auto" v-clickfeedback="'btn-purple'" @click.stop="handleClick" :key="JSON.stringify(currentInstructions[1])">{{currentInstructions[1]}}</span>
      </transition-group>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'VideoPlayerInstructions',
    computed:{
      ...mapGetters(['currentInstructions','motionDisabled','videoStatus']),
    },
    methods:{
      handleClick(){
        this.analyticsEvent('videoplayer_cta_clicked','features_used');
        this.$emit('swap');
      },
    }
  }
</script>


