<template>
    <button class="h-6 w-7" :title="label" :aria-label="label" @click="toggleBgVideo()">
        <img :src="bgVideoDisabled ? backgroundPlay:backgroundPause" alt="Background Play/Pause Icon">
    </button>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import backgroundPause from '@/assets/images/background-pause.svg'
import backgroundPlay from '@/assets/images/background-play.svg'

export default {
    name: 'ControlVolume',
    data(){
        return {
            backgroundPause,
            backgroundPlay
        }
    },
    computed: {
        ...mapGetters(['bgVideoDisabled']),
        label(){
            return this.bgVideoDisabled ? 'Play Background Video':'Pause Background Video';
        }
    },
    methods: {
        ...mapMutations(['setBgVideoDisabled']),
        toggleBgVideo(){
            if(this.bgVideoDisabled){
                this.analyticsEvent('nav_video_background_unpause_clicked','features_used');
                this.setBgVideoDisabled(false);
            } else {
                this.analyticsEvent('nav_video_background_pause_clicked','features_used');
                this.setBgVideoDisabled(true);
            }
        }
    }
}
</script>