<template>
  <div class="border border-ex-soft-purple rounded-lg flex flex-grow relative neon-border min-h-[350px] xxs:mx-5-safe mx-1 my-1">
    
    <button class="absolute top-0 left-1/2 -translate-x-1/2 rounded-b-lg border border-t-0 border-ex-soft-purple cursor-pointer" @click="toggleExpediaInfo" @mouseleave="showExpediaInfo = false">
      <p class="font-body text-xs py-1 mx-2 text-ex-soft-purple whitespace-nowrap">An Expedia Group Partnership <span class="inline-block w-4 ml-1"><img :src="infoIcon" alt="Info icon" class="h-4 mb-[3px] inline-block" aria-hidden="true"></span></p>
      
      <transition
            enter-active-class="duration-300 ease-out"
            enter-class="opacity-0 transform -translate-y-5"
            enter-to-class="opacity-100 transform translate-y-0"
            leave-active-class="duration-300 ease-in"
            leave-class="opacity-100 transform translate-y-0"
            leave-to-class="opacity-0 transform -translate-y-5"
          >
        <div class="absolute top-8 left-1/2 -translate-x-1/2 w-72 bg-ex-soft-purple text-center rounded top-chevron cursor-default" v-show="showExpediaInfo" @click.stop="toggleExpediaInfo">
          <p class="font-body text-xs p-4 text-black leading-relaxed">This content was paid for by <strong>LVCVA</strong> and created by Expedia Group Media Studio. The editorial staff at Expedia Group had no role in this content.</p>
        </div>
      </transition>
    </button>

    <transition
      mode="out-in"
      enter-active-class="duration-500 ease"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-500 ease"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      tag="div"
    >
      <div class="flex-grow flex flex-col sm:justify-center justify-stretch sm:items-center items-stretch pt-8">
        <div class="sm:flex-grow-0 flex-grow flex flex-col justify-center items-center xxs:mx-5 mx-1" key="1">
          <h1 class=" font-headline-bold xl:text-9xl sm:text-8xl xs:text-7.5xl text-7xl veryshort:text-7xl text-center xl:leading-[7rem] sm:leading-[5rem] xs:leading-[4.6rem] leading-[4rem] veryshort:leading-[4rem] select-none" aria-label="Step into the Entertainment Capital of the World For Sports, Dining, Concerts, Shows, Magic">STEP INTO THE <br class="sm:hidden" aria-hidden="true"> <span class="whitespace-nowrap" aria-hidden="true">ENTERTAINMENT CAPITAL</span> <br aria-hidden="true"> OF THE WORLD FOR <br class="sm:hidden" aria-hidden="true">           
            <span class="inline-block xl:w-44 w-36 xl:h-[6rem] sm:h-[4.5rem] xs:h-[4.2rem] h-[3.5rem] veryshort:h-[3.5rem] relative -ml-1 -mt-12">
              <span class="inline-block xl:h-[6.5rem] sm:h-[5.1rem] xs:h-[4.5rem] h-[4.2rem] veryshort:h-[4.2rem] overflow-y-hidden absolute xs:mt-0 -mt-1 veryshort:-mt-[1px] sm:transform-none sm:left-0 left-1/2 transform -translate-x-1/2 flex items-center">
                <transition
                mode="out-in"
                enter-active-class="duration-200 ease-out"
                :enter-class="motionDisabled ? 'opacity-0':'opacity-0 transform -translate-y-20'"
                enter-to-class="opacity-100 transform translate-y-0"
                leave-active-class="duration-300 ease-in"
                leave-class="opacity-100 transform translate-y-0"
                :leave-to-class="motionDisabled ? 'opacity-0':'opacity-0 transform translate-y-20'"
                >
                  <span :key="currentOption" class="inline-block font-headline-light font-light neon-text text-ex-white sm:text-left text-center mx-1" aria-hidden="true">{{ currentOption }}</span>
                  
                </transition> 
              </span>
            </span>
          </h1>
        </div>
        <button class=" btn-purple xl:w-96 sm:w-72 xl:mt-2 sm:mt-1 mt-0 xxs:mx-5 mx-2 xxs:mb-5 mb-2 flex justify-center items-center" v-clickfeedback="'btn-purple'" @click="begin"><img :src="playIcon" alt="Play icon" class="inline h-5 mr-2" aria-hidden="true">BEGIN</button>
      </div>
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import playIcon from '@/assets/images/play-icon.svg'
  import infoIcon from '@/assets/images/info-icon.svg'

  export default {
    name: 'IntroductionContent',
    data(){
      return {
        options: ['SPORTS','DINING','CONCERTS','SHOWS','MAGIC'],
        currentIndex: -1,
        intervalBetweenOptions: 1500,
        playIcon,
        infoIcon,
        showExpediaInfo: false,
      }
    },
    computed:{
      ...mapGetters(['motionDisabled']),
      currentOption(){
        return this.options[this.currentIndex % this.options.length]
      },
    },
    methods:{
      advance(){
        this.currentIndex++;
        setTimeout(this.advance, this.intervalBetweenOptions)
      },
      toggleExpediaInfo(){
        this.showExpediaInfo = !this.showExpediaInfo;
      },
      begin(){
        this.analyticsEvent('landing_begin_button_clicked', 'internal_links', 'BEGIN');
        this.$emit('begin')
      },
    },
    mounted(){
      this.advance();
    }
  }

  </script>