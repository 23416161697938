<template>
    <button class="h-4 w-5 flex items-center justify-between overflow-hidden" :title="label" :aria-label="label" @click="toggleMuted()">
        <div class="bg-ex-soft-purple h-4 w-1" :class="muted ? 'animate-[volume-off_1s_infinite]':'animate-[volume-1_1.5s_infinite]'"></div>
        <div class="bg-ex-soft-purple h-4 w-1" :class="muted ? 'animate-[volume-off_1s_infinite]':'animate-[volume-2_1.5s_infinite]'"></div>
        <div class="bg-ex-soft-purple h-4 w-1" :class="muted ? 'animate-[volume-off_1s_infinite]':'animate-[volume-3_1.5s_infinite]'"></div>
    </button>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    name: 'ControlVolume',
    computed: {
        ...mapGetters(['muted']),
        label(){
            return this.muted ? 'Unmute Sound':'Mute Sound';
        }
    },
    methods: {
        ...mapMutations(['setMuted']),
        toggleMuted(){
            if(this.muted){
                this.analyticsEvent('nav_audio_unmute_clicked','features_used');
                this.setMuted(false);
            } else {
                this.analyticsEvent('nav_audio_mute_clicked','features_used');
                this.setMuted(true);
            }
        }
    }
}
</script>